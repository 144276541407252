import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    dataLayer: any;
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      if (typeof window === 'undefined') {
        return;
      }
      //
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'virtualPageview',
      });
    });
    return () => {
      router.events.off('routeChangeComplete', () => {
        console.log('stoped');
      });
    };
  }, [router.events]);
  return <Component {...pageProps} />;
}
export default MyApp;
